import * as React from 'react';
import CardActions from '@mui/material/CardActions';
import Button from '@mui/material/Button';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Alert, AlertTitle } from '@material-ui/lab';
import { catalogApiRef, CatalogApi } from '@backstage/plugin-catalog-react';
import { identityApiRef, useApi } from '@backstage/core-plugin-api';
import useAsync from 'react-use/lib/useAsync';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      '& > * + *': {
        marginTop: theme.spacing(2),
      },
    },
  }),
);

export default function AnnouncementBanner() {
  const classes = useStyles();
  const identityApi = useApi(identityApiRef);
  const catalogApi: CatalogApi = useApi(catalogApiRef);
  const { value: groups } = useAsync(async () => {
    const profile = await identityApi.getBackstageIdentity();
    const response = await catalogApi.getEntities({
      filter: [{ kind: 'group', 'relations.hasMember': profile.userEntityRef }],
      fields: ['metadata', 'kind'],
    });
    return response.items;
  }, []);
  // member of a group
  if (
    (groups ?? []).filter(
      group => group.metadata.name !== 'sg_Users_Products&Engineering',
    ).length >= 1
  ) {
    return <Grid item xs={12} />;
  }

  // Not a member of a group
  return (
    <Grid item xs={12} md={4}>
      <div className={classes.root}>
        <Alert variant="outlined" severity="warning">
          <AlertTitle>
            <strong>Announcement</strong>
          </AlertTitle>
          <strong>
            You are not in a squad. Click the button below to join a squad and
            partake in squad activities.
          </strong>
          <CardActions style={{ justifyContent: 'center', marginTop: '10px' }}>
            <Button
              size="medium"
              variant="contained"
              color="success"
              href="/docs/default/location/core-catalog/how-to/01-add-your-squad/"
              target="_blank"
            >
              Join Squad
            </Button>
          </CardActions>
        </Alert>
      </div>
    </Grid>
  );
}
