import React from 'react';
import {
  DEFAULT_NAMESPACE,
  stringifyEntityRef,
} from '@backstage/catalog-model';
import {
  SidebarItem,
  SidebarSubmenu,
  SidebarSubmenuItem,
} from '@backstage/core-components';
import {
  IconComponent,
  identityApiRef,
  useApi,
  useRouteRef,
} from '@backstage/core-plugin-api';
import useAsync from 'react-use/esm/useAsync';
import {
  catalogApiRef,
  CatalogApi,
  entityRouteRef,
} from '@backstage/plugin-catalog-react';
import { getCompoundEntityRef } from '@backstage/catalog-model';

export const MySquadsSidebarItem = (props: {
  singularTitle: string;
  pluralTitle: string;
  icon: IconComponent;
  filter?: Record<string, string | symbol | (string | symbol)[]>;
}) => {
  const { singularTitle, pluralTitle, icon, filter } = props;

  const identityApi = useApi(identityApiRef);
  const catalogApi: CatalogApi = useApi(catalogApiRef);
  const catalogEntityRoute = useRouteRef(entityRouteRef);

  const { value: groups } = useAsync(async () => {
    const profile = await identityApi.getBackstageIdentity();

    const response = await catalogApi.getEntities({
      filter: [
        {
          kind: 'group',
          'relations.hasMember': profile.userEntityRef,
          ...(filter ?? {}),
        },
      ],
      fields: ['metadata', 'kind', 'spec'],
    });

    return response.items;
  }, []);

  // Not a member of any groups
  if (!groups?.length) {
    return null;
  }

  // Only member of one group
  if (groups.length === 1) {
    const group = groups[0];
    return (
      <SidebarItem
        text={singularTitle}
        to={catalogEntityRoute(getCompoundEntityRef(group))}
        icon={icon}
      />
    );
  }

  // Member of more than one group
  return (
    <SidebarItem icon={icon} text={pluralTitle}>
      <SidebarSubmenu title={pluralTitle}>
        {groups?.map(function groupsMap(group) {
          return (
            <SidebarSubmenuItem
              title={
                (group.spec?.profile as { displayName?: string })
                  ?.displayName ?? group.metadata.name
              }
              subtitle={
                group.metadata.namespace !== DEFAULT_NAMESPACE
                  ? group.metadata.namespace
                  : undefined
              }
              to={catalogEntityRoute(getCompoundEntityRef(group))}
              icon={icon}
              key={stringifyEntityRef(group)}
            />
          );
        })}
      </SidebarSubmenu>
    </SidebarItem>
  );
};
