import {
  createPlugin,
  createRoutableExtension,
  createComponentExtension,
} from '@backstage/core-plugin-api';

import { rootRouteRef } from './routes';

import { createCardExtension } from '@backstage/plugin-home-react';

import type { ToolkitContentProps } from './components/ToolKit';

export const azureHomePrsPlugin = createPlugin({
  id: 'azure-home-prs',
  routes: {
    root: rootRouteRef,
  },
});

export const AzureHomePrsPage = azureHomePrsPlugin.provide(
  createRoutableExtension({
    name: 'AzureHomePrsPage',
    component: () =>
      import('./components/PullRequests/').then(m => m.PullRequests),
    mountPoint: rootRouteRef,
  }),
);

export const HomePageToolkit = azureHomePrsPlugin.provide(
  createCardExtension<ToolkitContentProps>({
    name: 'HomePageToolkit',
    components: () => import('./components/ToolKit'),
  }),
);

export const AzureHomePrsCard = azureHomePrsPlugin.provide(
  createComponentExtension({
    name: 'AzureHomePrsCard',
    component: {
      lazy: () => import('./components/PullRequests').then(m => m.PullRequests),
    },
  }),
);

export const AzureReviewPrsCard = azureHomePrsPlugin.provide(
  createComponentExtension({
    name: 'AzureReviewPrsCard',
    component: {
      lazy: () =>
        import('./components/ReviewRequests').then(m => m.ReviewRequests),
    },
  }),
);
